import { LogDebug } from "../../helpers/LogHelper";
import { createSlice } from "@reduxjs/toolkit";
import {
  collection,
  query,
  where,
  doc,
  setDoc,
  getDocs,
  serverTimestamp,
  updateDoc,
} from "firebase/firestore";
import { faker } from "@faker-js/faker";
import { getFirestore } from "../../app/firebase";

/**
 * initialState = {
 *  lastUpdated: Timestamp;
 *  dataAvailable: boolean;
 *  loading: false;
 *  employees: [
 *    {
 *      firstName: string;
 *      lastName: string;
 *      email: string;
 *      uid: string;
 *      startDate: Timestamp;
 *      tenureDate: Timestamp;
 *    }
 *  ]
 * }
 */
const initialState = {
  employees: [],
  orgTree: [],
};

const adminEmployeesSlice = createSlice({
  name: "adminEmployees",
  initialState,
  reducers: {
    setEmployees(state, action) {
      LogDebug(
        setEmployees.name,
        "Initiated setting employees state after loading.",
        action.payload
      );
      Object.assign(state, {
        ...action.payload,
        lastUpdated: new Date(),
        dataAvailable: true,
        loading: false,
      });
    },
    setLoading(state, action) {
      LogDebug(
        setEmployees.name,
        "Initiated setting employees loading state.",
        action.payload
      );
      Object.assign(state, {
        ...action.payload,
        lastUpdated: new Date(),
        dataAvailable: false,
        loading: true,
      });
    },
  },
});

/**
 * Returns fake users
 * @returns
 */
export const fetchFakeEmployeesAndSetData = () => {
  return async (dispatch) => {
    const employees = [];
    dispatch(adminEmployeesSlice.actions.setLoading());

    for (let i = 0; i < 10; ++i) {
      const firstName = faker.name.firstName();
      const lastName = faker.name.lastName();
      employees.push({
        firstName,
        lastName,
        email: faker.internet.email(firstName, lastName),
        uid: faker.datatype.uuid(),
        startDate: faker.date.birthdate(),
        tenureDate: new Date(),
      });
    }

    dispatch(
      adminEmployeesSlice.actions.setEmployees({ employees: employees })
    );
  };
};

/**
 * The algorithm below generates a children structure for each node that will contain all
 * the children for each unit.
 * @param {} employees
 * @returns
 */
const generateOrgTree = (employees) => {
  const map = {};
  const roots = [];

  for (let i = 0; i < employees.length; ++i) {
    map[employees[i].uid] = i;
    employees[i].children = [];
  }

  for (let i = 0; i < employees.length; ++i) {
    const node = { ...employees[i], id: employees[i].uid };

    if (node.reportsTo) {
      employees[map[node.reportsTo]].children.push(node);
    } else {
      roots.push(node);
    }
  }

  return roots;
};

/**
 * Expects a valid UID that is used to search for employees belonging to that
 * user.
 *
 * Keep fetchEmployeesAndSetData and fecthAllEmployees in sync.
 * @param {UID} employerId
 * @returns
 */
export const fetchEmployeesAndSetData = (employerId) => {
  // eslint-disable-next-line no-unused-vars
  return async (dispatch, getState) => {
    if (!employerId) return;

    // const state = getState();
    const employees = [];
    const everyEmployee = [];

    dispatch(adminEmployeesSlice.actions.setLoading());
    const db = getFirestore();

    const usersRef = collection(db, "users");

    const q = query(usersRef, where("employerId", "==", employerId));

    const querySnapshot = await getDocs(q);

    for (const employee of querySnapshot.docs) {
      const dbData = employee.data();

      const readableEmployee = {
        ...dbData,
        startDate: dbData.startDate.toDate(),
      };

      // if (dbData.uid !== state.user.firebaseUid) {
      employees.push(readableEmployee);
      // }

      everyEmployee.push(readableEmployee);
    }

    const orgTree = generateOrgTree(everyEmployee);

    dispatch(
      adminEmployeesSlice.actions.setEmployees({
        employees: employees,
        orgTree: orgTree,
      })
    );
  };
};

export const uploadNewEmployeesData = (employerId, newEmployees) => {
  return async (dispatch, getState) => {
    const { adminEmployees } = getState();
    const db = getFirestore();
    dispatch(adminEmployeesSlice.actions.setLoading());
    const updatedEmployees = Object.assign([], adminEmployees.employees);
    for (const employee of newEmployees) {
      // Add data to the firebase
      await setDoc(doc(db, "users", employee.uid), {
        ...employee,
        createTimestamp: serverTimestamp(),
        lastUpdated: serverTimestamp(),
      });
    }
    updatedEmployees.concat(newEmployees);
    dispatch(
      adminEmployeesSlice.actions.setEmployees({
        employees: updatedEmployees,
      })
    );
  };
};

export const updateEmployeeData = (employeeUid, updatedData) => {
  return async (dispatch, getState) => {
    const { adminEmployees } = getState();

    const employeesExistingData = adminEmployees.employees;

    employeesExistingData.map((content) =>
      content.uid === employeeUid ? { ...content, ...updatedData } : content
    );

    const db = getFirestore();

    const usersRef = doc(db, "users", employeeUid);

    await updateDoc(usersRef, {
      ...updatedData,
      lastUpdated: serverTimestamp(),
    });

    dispatch(
      adminEmployeesSlice.actions.setEmployees({
        employees: employeesExistingData,
      })
    );
  };
};

export const { setEmployees, setLoading } = adminEmployeesSlice.actions;

export default adminEmployeesSlice.reducer;
