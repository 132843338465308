import { LogDebug } from "../../helpers/LogHelper";
import { createSlice } from "@reduxjs/toolkit";
import { doc, setDoc, getDoc, serverTimestamp } from "firebase/firestore";
import { getFirestore } from "../../app/firebase";

/**
 * Dynamic object with surveyName
 * initialState = {
 *  [surveyName]: {}
 * }
 */
const initialState = {
  survey: {},
};

const employeeSlice = createSlice({
  name: "employeeSlice",
  initialState,
  reducers: {
    setSurveyData(state, action) {
      LogDebug(
        setSurveyData.name,
        "Initiated setting employees state after loading.",
        action.payload
      );
      Object.assign(state, {
        ...action.payload,
        lastUpdated: new Date(),
        dataAvailable: true,
        loading: false,
      });
    },
    setLoading(state, action) {
      LogDebug(
        setLoading.name,
        "Initiated setting employee survey data in loading state.",
        action.payload
      );
      Object.assign(state, {
        ...action.payload,
        lastUpdated: new Date(),
        dataAvailable: false,
        loading: true,
      });
    },
  },
});

/**
 * Expects a valid UID that is used to search for employees belonging to that
 * user.
 * @param {UID} uidToFindEmployeesBy
 * @returns
 */
export const fetchSurveyData = (uid, surveyName) => {
  return async (dispatch) => {
    const db = getFirestore();

    const surveyRef = doc(db, `users/${uid}/survey/`, surveyName);

    const docSnap = await getDoc(surveyRef);

    if (docSnap.exists()) {
      dispatch(
        employeeSlice.actions.setSurveyData({
          [surveyName]: docSnap.data(),
        })
      );
    } else {
      dispatch(employeeSlice.actions.setSurveyData({ [surveyName]: {} }));
    }
  };
};

export const fetchSurveyDataWithoutDispatch = async (uid, surveyName) => {
  const db = getFirestore();

  const surveyRef = doc(db, `users/${uid}/survey/`, surveyName);

  const docSnap = await getDoc(surveyRef);

  if (docSnap.exists()) {
    return docSnap.data();
  }
  return undefined;
};

export const updateSurveyData = (uid, data, surveyName) => {
  return async (dispatch) => {
    const db = getFirestore();

    const surveyRef = doc(db, `users/${uid}/survey/`, surveyName);

    await setDoc(
      surveyRef,
      {
        ...data,
        lastUpdated: serverTimestamp(),
      },
      { merge: true }
    );

    const userDataRef = doc(db, "users", uid);

    await setDoc(
      userDataRef,
      {
        personalInformationSurvey: {
          completed: true,
          lastUpdate: serverTimestamp(),
        },
      },
      { merge: true }
    );

    dispatch(employeeSlice.actions.setSurveyData({ [surveyName]: data }));
  };
};

export const { setSurveyData, setLoading } = employeeSlice.actions;

export default employeeSlice.reducer;
